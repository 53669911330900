import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Main from './Pages/Main';
import Hug from './Pages/Hug';
import NotFound from './Pages/NotFound';
import Header from './Common/Header'
import './styles/scss/common.scss'


function App() {
  return (
    <div className="anbang">
      <Header />
      <div id="Content">
        <Routes>
					<Route path="/" element={<Main />}></Route>
					<Route path="/hug*" element={<Hug />}></Route>
					{/* 상단에 위치하는 라우트들의 규칙을 모두 확인, 일치하는 라우트가 없는경우 처리 */}
					<Route path="/*" element={<NotFound />} />
				</Routes>
      </div>
    </div>
  );
}

export default App;

import React from 'react';
import { Steps } from 'antd';
import position from'@styles/images/position.png'

const step2List = [
    {
        link:'https://www.gov.kr/portal/main/nologin', 
        btnText:'정부24', 
    },
    {
        link:'http://www.iros.go.kr/PMainJ.jsp', 
        btnText:'인터넷등기소', 
    },
    {
        link:'https://blog.naver.com/sut2437/223016709826', 
        btnText:'서류목록', 
        source:'NAVER - 허그 전세보증보험 청구 _ 제출서류, 준비물 등 by ZZIN' 
    }
]
const step3List = [
    {
        link:'https://www.khug.or.kr/hug/web/gb/eg/gbeg000023.jsp',
        btnText:'허그 관리센터 확인'
    }
]
const step6List = [
    {
        link:'https://cafe.naver.com/anbangcafe',
        btnText:'다른사례 내용공유'
    },
    {
        link:'https://open.kakao.com/o/sNLUBMkg',
        btnText:'수정문의-(내용변경 or 서류변경)'
    }
]

const Step1 = (props) => {
    // eslint-disable-next-line react/prop-types
    const {miniStep, onClickNextMiniStep, ButtonWrap} = props;
    return (
        <>
            <Steps
                progressDot
                current={miniStep}
                direction="vertical"
                items={[
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(0)}>임차권등기명령이 신청 또는 완료되었을까요?</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(0)}>
                                그리고 계약만료일+30일이 임박했거나, 지났을까요?
                            </p>
                            <ButtonWrap
                                next={1}
                                // text='임차권등기명령이 되어 있 해주세요.'
                                idx={0}
                            />
                        </>
                        ),
                     },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(1)}>허그보증보험 반환을 위한 준비해야 할 서류</span></>),
                        description: (
                        <>
                            <p className="tipWrap strong">
                                1.임차권등기가 등재된 부동산등기부등본<br />
                                <span className="indent">- 임차권등기가 완료된 등기부등본</span><br />
                                2.임차권등기명령결정문<br />
                                <span className="indent">- 전자소송 로그인 → 송달문서확인 → 전체송달문서</span><br />
                                3.신분증 사본<br />
                                4.인감도장<br />
                                5.인감증명서 2부(본인서명사실확인서 2부 대체가능)<br />
                                <span className="indent">- 도장 만들거나, 챙기고서 주민센터 방문</span><br />
                                6.주민등록초본, 등본(주소변동내역포함, 주민번호전부표기, 임차권등기후 발급)<br />
                                <span className="indent">- 주소 변동 내역 모두 포함, 임차권등기가 등본에 기재된 이후 발급</span><br />
                                7.임대차계약서 원본(은행 보관중인 경우 사본 제출, 확정일자확인)<br />
                                <span className="indent">- 안심대출일경우 복사본 가능 그리고 <b>허그에서 가져가기에 복사본 달라고 요청</b></span><br />
                                8-1.(안심대출의 경우)은행지점 통장사본→은행에 요청<br />
                                <span className="indent">- 은행지점 명의의 통장으로 돈이 환급되며, 차액은 임차인에게 전달.</span><br />
                                8-2.(반환보증의 경우)금융거래확인서(전세대출 은행 발급), 임차인 통장사본<br />
                                9.입금내역 및 영수증<br />
                                <span className="indent" style={{marginBottom:'0px'}}>- 입금날짜&금액&임대인 계좌번호 모두 보이도록 출력(가족(부모,형재 등)이 보냈어도 괜찮음.)</span><br />
                                <span className="indent" style={{marginBottom:'0px'}}>- 은행→임대인, 임차인→임대인 입금내역 모두 필요.</span><br />
                                <span className="indent">- 입금내역 합 = 보증금</span><br />
                                10.임대차계약 해지 또는 종료 증명서류<br />                                
                                <span className="indent">- 문자/카톡 : 임대인번호, 문자수신일, 내용 표기(임대인번호확인)</span>
                                <span className="indent">- 통화녹음 : 공인속기사가 작성한 녹취록(임대인번호확인)</span>
                                <span className="indent">- 내용증명 : 내용증명우편서류</span>
                                <span className="indent">- 공시송달 : 공시송달결정문</span>
                                <span className="indent">- 전세계약종료확인서 : 임대인, 임차인 신분증 및 인감증명서 첨부</span>
                            </p>
                            <ButtonWrap
                                next={2}
                                idx={1}
                                list={step2List}
                                oktext='서류준비완료'
                            />
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(2)}>준비된 서류를 챙기고서 HUG관리센터 위치 확인.</span></>),
                        description: (
                        <>
                            <p className="tipWrap" onClick={() => onClickNextMiniStep(2)}>
                                ※ 주거지마다 관리센터가 다르므로 미리 확인 후 찾아가야합니다.<br />
                                수도권 기준 강서 / 강북 / 경기도 등 거주하시는 지역마다 관리센터가 다르기 때문에 확인해 줍니다.
                                <div className='imgBox'>
                                    <img src={position} />
                                </div>
                            </p>
                            <ButtonWrap
                                next={3}
                                idx={2}
                                oktext='확인 완료'
                                list={step3List}
                                // addBtn={<><Button onClick={() => onClickNextMiniStep(3)}>내용증명이 반송 되었어요</Button></>}
                            />                           
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(3)}>관리센터 방문 후 서류제출</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(3)}>
                               ※ 방문은 영업시간 <b className='red'>2~30</b>분전 도착을 추천합니다.(거의 정각에 도착해도 대기자가 많기 때문에 미리 가서 기다리는걸 추천드립니다.)<br />
                            </p>
                            <ButtonWrap
                                idx={3}
                                next={4}
                                oktext='예'
                                // addBtn={<Button onClick={() => onClickNextContent(2)}>다음단계</Button>}
                            />      
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(4)}>서류제출 후 담당자 확인</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(4)}>
                               ※ 서류가 최종승인이 될 경우 담당자에게 연락이 옵니다.<br />
                               이사날짜를 조율하여 담당자에게 언제 퇴거하시겠다고 전달하면 됩니다.<br />
                               그리고 퇴거일에 <b className='red'>퇴거담당자와 몇시에 도착하는지 확인을 꼭 하시길 바라겠습니다.</b>
                            </p>
                            <ButtonWrap
                                next={5}
                                idx={4}
                                // oktext='예'
                                // addBtn={<Button onClick={() => onClickNextContent(2)}>다음단계</Button>}
                            />      
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(5)}>퇴거일</span></>),
                        description: (
                        <>
                            <p className="tipWrap strong">
                                0.퇴거담당자 도착전 짐빼기<br />
                                <p className="indent">
                                    담당자가 도착하기전에 <b className='red'>무거운짐</b>만 빼서 기다려줍니다.<br />
                                    담당자가 도착 이후에 가벼운 짐을 빼고서 아래의 서류를 진행해 주시면 됩니다.
                                </p>
                                <br />
                                1.수도, 전기, 가스 요금
                               <p className="indent">
                                    - 해당기관에서 받은 요금 부과기간, 금액이 기재된 내역서<br />
                                    - 요금을 이체한 모바일 계좌 내역.<br />
                                    * 통화가 어려울 수 있으니 9시에 바로 진행<br />
                                    * 전기(7번), 수도, 가스계량기 사진<br />
                                    <b>※ 전기 같은경우 왼쪽 위 07 번이 나타날때 사진을 찍어야 합니다.</b><br />
                                    <br />
                                    <b>수도,전기,도시가스 계량기 사진 3장 / 수도,전기,도시가스 이사정산내역 사진 3장 / 수도,전기,도시가스 이체내역 3장 = 총 9장</b><br />
                                    <br />
                                    공과금 연락처<br />
                                    - 한전: 123<br />
                                    - 도시가스: 1588-5788 또는 타지역 도시가스<br />
                                    - 수도: 각 지자체로 문의<br />

                                </p>
                                <br />
                                2.관리비 완납 증명 또는 완납을 명시한 문자 메시지
                                <p className="indent">
                                    ** 관리비 완납 확인 요청 문자<br />
                                    (관리사무소 또는 관리인에게)<br />
                                    ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ<br />
                                    00 빌라 (아파트) 000호 입니다<br />
                                    저희가  00 일 이사를 가는데 그날까지 관리비0000원을 완납하였음을 확인하여 주시기 바랍니다<br />
                                    ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ<br />
                                    ** 확인서가 없는 경우 카톡이 아닌 문자로 완납사실 확인 받아서 전달<br />
                                    (상대방 전화번호가 나오도록)<br />
                                </p>
                                <br />
                                3. 임대인에게 송부한 문자 내역<br />
                                <p className="indent">
                                    ** 임대인에게 보내는 문자<br />
                                    ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ<br />
                                    안녕하십니까 ?<br />
                                    저희가 00일 이사를 하게 되어 다음 내용을 전달 드립니다<br />
                                    <br />
                                    주소 : (임차인 주소)<br />
                                    성명 : (임차인 성명)<br />
                                    전자도어락비밀번호 : <br />
                                    공용현관문비밀번호 : <br />
                                    내부키 유/무 : (있는 경우 보관장소위치 + 사진)<br />
                                    ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ<br />

                                    *화면 맨 위에 임대인 전화번호가 보이도록 문자로 송부한 후 캡쳐<br />
                                </p>
                            </p>
                            <ButtonWrap
                                idx={5}
                                next={6}
                                oktext='퇴거 담당자분께 서류를 다 전달하였습니다.'
                                // addBtn={<Button onClick={() => onClickNextContent(2)}>다음단계</Button>}
                            />      
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(6)}>마지막 보증금 반환</span></>),
                        description: (
                        <>
                            <p className="tipWrap strong"  onClick={() => onClickNextMiniStep(6)}>
                               ※ 서류제출이 끝날경우 퇴거 담당자분께서 서류를 제출 후 기다려줍니다.(승인이 될때까지)<br />
                               그리고 완료되었다는 소식을 듣더라도 기다려줍니다.<br />
                               안심대출일 경우 대출을 받았던 은행에 연락을 해줍니다.<br />
                               반환보증일 경우 보증금을 돌려받았는지 확인해줍니다.<br />
                               <br />
                               긴내용 읽어주셔서 감사합니다 그리고 고생하셨습니다.<br />
                               <br />
                               혹시 반환절차에 대해서 내용과 다른 고충을 느꼈다면 &apos;<b className="red">다른사례 내용공유</b>&apos;의 카페에서 내용 공유 부탁드리겠습니다.<br />
                               똑같은 상황이 있는 다른분들께도 도움이 될거라 생각합니다..!<br />
                            </p>
                            <ButtonWrap
                                idx={6}
                                oktext=''
                                // addBtn={<Button onClick={() => onClickNextContent(2)}>다음단계</Button>}
                                list={step6List}
                            />      
                        </>
                        ),
                    },
                ]}
            />
        </>
    )
}

export default Step1;
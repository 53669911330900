import React from 'react';
import { Steps, Button } from 'antd';

const step2List = [
    {
        link:'https://blog.naver.com/khi5223/223104846582', 
        btnText:'임차권등기명령 서류 준비', 
        source:'NAVER - [HUG 전세보증보험 이행청구 후기] 2. 임차권등기명령신청(제주지방법원) 서류 준비 제출 by 매니유' 
    },
    {
        link:'https://www.gov.kr/portal/main/nologin', 
        btnText:'정부24', 
    },
    {
        link:'https://www.wetax.go.kr/main.do', 
        btnText:'위택스', 
    },
    {
        link:'http://www.iros.go.kr/PMainJ.jsp', 
        btnText:'인터넷등기소', 
    },
    {
        link:'https://www.eais.go.kr/', 
        btnText:'세움터', 
    },
    {
        link:'https://m.blog.naver.com/sut2437/222997196939', 
        btnText:'부동산목록', 
        source:'NAVER - 임차권등기명령에 필요한 부동산목록 직접 작성하기 by ZZIN' 
    }
]
const step3List = [
    {
        link:'https://blog.naver.com/sut2437/222995353820',
        btnText:'임차권등기명령 전자소송 신청방법', 
        source:'NAVER - 셀프로 임차권등기명령 전자소송 신청 한방에 통과 후기 by ZZIN'
    },
    {
        link:'https://blog.naver.com/sut2437/223006430360',
        btnText:'임차권등기명령 전자소송 주소보정명령', 
        source:'NAVER - 임차권등기명령 전자소송 주소보정명령 하는 방법 by ZZIN'
    }
]

const Step1 = (props) => {
    // eslint-disable-next-line react/prop-types
    const {miniStep, onClickNextMiniStep, ButtonWrap, onClickNextContent} = props;
    return (
        <>
            <Steps
                progressDot
                current={miniStep}
                direction="vertical"
                items={[
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(0)}>내용증명/공시송달이 집주인에게 전달되었을까요?</span></>),
                        description: (
                        <>
                            <ButtonWrap
                                next={1}
                                text='내용증명을 우선시 해주세요.'
                                idx={0}
                            />
                        </>
                        ),
                     },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(1)}>임차권등기명령 준비 서류</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(1)}>
                                1.등록면허세(위택스)<br />
                                2.등기신청수수료(인터넷 등기소)<br />
                                3.등기부등본(인터넷 등기소)<br />
                                4.건축물 현황도(세움터)<br />
                                5.부동산 목록<br />
                                6.주민등록등본(정부24)<br />
                                7.계약 종료 증빙 문서(내용증명 or 카톡 or 문자 등)<br />
                                8.임대차 계약서(확정일자, 전입신고 필수)<br />
                                9.임차권등기명령신청서(대법원 홈페이지)<br />
                            </p>
                            <ButtonWrap
                                next={2}
                                idx={1}
                                list={step2List}
                                oktext='서류준비완료'
                            />
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(2)}>임차권등기명령 준비서류가 완료되었다면 직접 신청해줍니다.</span></>),
                        description: (
                        <>
                            <p className="tipWrap" onClick={() => onClickNextMiniStep(2)}>
                                ※ 임차권등기명령 실행까지는 생각보다 <b className='red'>오래 걸리므로 여유 부리지 말고 빨리 신청</b>하시는게 좋습니다.<br />
                                그리고 <b className='red'>보정명령</b>이 있을수도 있기에 확인하고서 빠른 보정을 해주시는게 좋습니다.<br />
                                <b>임차권등기명령이 완료되었을 경우 등기부등본에서 등기명령</b>이 나타날때까지 기다려줍니다.
                            </p>
                            <ButtonWrap
                                next={3}
                                idx={2}
                                oktext='임차권등기명령 신청완료'
                                list={step3List}
                                // addBtn={<><Button onClick={() => onClickNextMiniStep(3)}>내용증명이 반송 되었어요</Button></>}
                            />                           
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(3)}>혹시 모를 상황에 은행 전세대출연장 신청</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(3)}>
                                ※ 구비서류 : <br />
                                <p className="indent">
                                    1. 신분증<br />
                                    2. 주민등록등본<br />
                                    3. 가족관계증명서<br />
                                    4. 확정일자가 있는 임대차계약서<br />
                                    5.임차권등기명령 접수증<br />
                                </p>
                                <b className="red">대출 연체 시 연체이자 발생 및 신용도 하락</b>을 방지할 수 있습니다.<br />
                                <b className="red">은행마다 대출연장 서류가 다르니 미리 연락을 하고서 추가 서류를 준비해주세요.</b><br />
                                <b>Hug전세대출이 아닌 Hug보증보험일 경우 임차권등기명령 신청 후 연장가능</b><br/>
                                그리고 보증금을 돌려받기전 <b className="red">절대이사금지</b>.
                            </p>
                            <ButtonWrap
                                idx={3}
                                oktext=''
                                addBtn={<Button onClick={() => onClickNextContent(2)}>다음단계</Button>}
                            />      
                        </>
                        ),
                    },
                   
                ]}
            />
        </>
    )
}

export default Step1;
import React from 'react';
import AntCard from '@Components/AntCard';
import '@styles/scss/Main.scss';
import {
    ProfileOutlined,
    RightOutlined
  } from '@ant-design/icons';

const Main = () => {
    return (
        <>
            <div className="main">
                <div className="tip">
                    <span><em><RightOutlined /></em>해당 정보는 전세반환보증에 대한 참고를 하시길 바라겠습니다.</span>
                </div>
                <div className="guaranteeList">
                    <AntCard title='주택도시보증공사(HUG)' 
                        link="hug"
                        Icon={<ProfileOutlined />} 
                        content={
                            <>
                                HUG에 관련된 보증보험 반환 절차에 대한 가이드 입니다.<br />
                                그리고 해당 관련된 서류 및 절차에 대한 정보를 확인하실 수 있습니다.
                            </>
                        }
                    />
                    <AntCard title='SGI서울보증' 
                        link=""
                        content={
                            <>
                                준비중입니다
                            </>
                        }
                    />
                    <AntCard title='한국주택금융공사(HF)' 
                        link=""
                        content={
                            <>
                                준비중입니다
                            </>
                        }
                    />
                </div>
            </div>
        </>
    )
}

export default Main;
import React from 'react';
import { Steps, Button } from 'antd';

const step3List = [
    {
        link:'https://blog.naver.com/mintlight/223092518932', 
        btnText:'내용증명 보내는 방법', 
        source:'NAVER - HUG 전세보증보험 전세보증금 반환 신청 방법 1탄 (우체국 내용증명 작성, 보내는 방법) by 머니스낵' 
    },
    {
        link:'https://service.epost.go.kr/iservice/usr/trace/usrtrc001k01.jsp', 
        btnText:'국내우편(등기)배송조회', 
    }
]
const step4List = [
    {
        link:'https://blog.naver.com/PostView.naver?blogId=rlawndbf1010&logNo=222803645940&proxyReferer=',
        btnText:'법원 공시송달방법', 
        source:'NAVER - 전세보증금반환청구 내용증명부터 공시송달까지 총정리 feat.HUG허그전세보증보험 by 율또'
    }
]

const Step1 = (props) => {
    // eslint-disable-next-line react/prop-types
    const {miniStep, onClickNextMiniStep, ButtonWrap, onClickNextContent} = props;
    return (
        <>
            <Steps
                progressDot
                current={miniStep}
                direction="vertical"
                items={[
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(0)}>허그 만료일자가 4~6개월 전인가요?</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(0)}>
                                ※ 해당 내용이 정답이 아닙니다. <b className="red">참고</b> 부탁드리겠습니다.<br />
                            </p>
                            <ButtonWrap
                                next={1}
                                text='4~6개월 전이 아닐경우 조금만 기다려 주세요.'
                                idx={0}
                            />
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(1)}>집주인이 연락을 받지 않거나, 등기부등본에 압류가 걸려있지는 않으신가요?</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(1)}>
                                ※ &apos;우리집은 괜찮겠지&apos; 라는 생각을 가지지 말고 미리 대비하여야 합니다.<br />
                                그리고 해당 방법은 <b className='red'>카톡, 메세지 통보가 아닌 내용증명</b>을 기준으로 진행되는점 참고 부탁드리며,<br />
                                각 세입자 분들의 상황이 달라 보증보험반환 절차가 달라질 수 있는점 한번더 참고 부탁드립니다.
                            </p>
                            <ButtonWrap
                                next={2}
                                idx={1}
                            />
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(2)}>최소 4개월 전까지 내용증명을 전달하여야 합니다.<br /> 내용증명을 집주인한테 전달되었을까요?</span></>),
                        description: (
                        <>
                            <p className="tipWrap" onClick={() => onClickNextMiniStep(2)}>
                                ※ 내용증명을 집주인에게 도착하여야 효력이 발생합니다.<br />
                                <b className="red">폐문부재가 끝이 아닌 집주인에게 전달</b>되어야 합니다.
                            </p>
                            <ButtonWrap
                                next={4}
                                idx={2}
                                list={step3List}
                                addBtn={<><Button onClick={() => onClickNextMiniStep(3)}>내용증명이 반송 되었어요</Button></>}
                            />                           
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(3)}>내용증명이 반송되었을경우</span></>),
                        description: (
                        <>
                            <p className="tipWrap" onClick={() => onClickNextMiniStep(3)}>
                                ※ 내용증명을 보내도 <b>임대인의 실제 주소가 아니거나 거부</b>를 하면서 <b className="red">반송</b> 되면
                                이 <b className="red">반송 내용 증명서, 임차인(세입자), 신분증, 임대차 계약서</b>를 가지고 <br />
                                <b className="red">관할 주민센터</b>에 방문해서
                                내용증명이 반송되었는데 임대인의 <b className="red">주민등록초본</b>을 발급해달라고 하면 임대인의 실거주 주소를 알 수 있습니다.<br />

                                그 이후 초본상 <b className="red">실거주 주소</b>로 다시 한번 내용증명을 보내시면 됩니다.
                                그래도 또 반송이 된다? 또 보내셔야 합니다.<br />
                                <b className="red">3번까지 발송해도 받지않는 다면 공시송달 신청</b>을 할 수 있는 조건이 채워지기 때문입니다.<br />

                                그렇게 <b>변경된 주소(변경되지 않았다면 기존 주소) 그대로 2번 더 발송하여 3번</b>을 채워줍니다.
                            </p>
                            <ButtonWrap
                                next={4}
                                idx={3}
                                oktext='내용증명/공시송달이 완료되었습니다.'
                                list={step4List}
                            />                           
                        </>
                        ),
                    },
                    {
                        title: (<><span onClick={() => onClickNextMiniStep(4)}>집주인한테 배달완료 하였거나, 법원 공시송달이 완료되었을때</span></>),
                        description: (
                        <>
                            <p className="tipWrap"  onClick={() => onClickNextMiniStep(4)}>
                                ※ 내용증명 또는 법원 공시송달이 완료되었을 경우 <b>계약만료일(퇴거일)</b>까지 기다려 줍니다.<br />
                                <b className="red">전세금을 못 돌려 받으셨다면 계약만료일(퇴거일) 다음날짜에 임차권등기명령</b>을 신청할 수 있습니다.
                            </p>
                            <ButtonWrap
                                next={5}
                                idx={4}
                                oktext=''
                                addBtn={<Button onClick={() => onClickNextContent(1)}>다음단계</Button>}
                            />      
                        </>
                        ),
                    },
                    
                   
                ]}
            />
        </>
    )
}

export default Step1;
import React from 'react';

const NotFound = () => {
    return (
        <div>
            준비중입니다.  
        </div>
    )
}

export default NotFound;
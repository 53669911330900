import React, {useState, useRef} from 'react';
import { Steps, message, Button } from 'antd';
import '@styles/scss/Hug.scss'
import Step1 from './HugFolder/Step1';
import Step2 from './HugFolder/Step2';
import Step3 from './HugFolder/Step3';


const Hug = () => {
    const scrollRef = useRef([]);
    const [miniStep, setMiniStep] = useState(0);
    const [footerStep, setFooterStep] = useState(0);
    const [messageApi, contextHolder] = message.useMessage();



    const onClickNextMiniStep = (step) => {
        setMiniStep(step);
        if(scrollRef.current[step]) {
            setTimeout(function() {
                scrollRef.current[step].scrollIntoView({ behavior: "smooth" });
            },500)
            
        }
    }

    const onClickNextContent = (step) => {
        if(footerStep !== step) {
            setFooterStep(step)
            setMiniStep(0)
        }
    }

    const warningAlert = (text) => {
        messageApi.open({
            type: 'warning',
            content: text,
        });
    };
    
    // eslint-disable-next-line react/prop-types
    const ButtonWrap = ({next, oktext = '예', text = '', list = [], addBtn, idx = 0}) => {
        
        return (
            <div className="HugButtonWrap" ref={(el) => (scrollRef.current[idx] = el)}>
                {oktext && (<Button type="primary" onClick={() => onClickNextMiniStep(next)}>{oktext}</Button>)}
                {text && text.length > 0 && (
                    <Button onClick={() => warningAlert(text)}>아니요</Button>
                )}
                
                {list && list.length > 0 && (
                    list.map((data) => {
                        return (
                            <>
                                <a href={data.link} target="_blank" rel="noopener noreferrer"><Button >{data.btnText}</Button></a>
                            </>
                        )
                    })
                )}
                {addBtn}
                {list && list.length > 0 && (
                    <div className="sourceWrap">
                        {list.map((data) => {
                            return (
                                <>
                                    <p className="source">{data.source && data.source.length > 0 && (<>출처 : {data.source}</>)}</p>
                                </>
                            )
                        })}
                    </div>
                )}
            </div>
        )
    }
    
    return (
        <div className="hugWrap">
            {contextHolder}
            {footerStep === 0 && (
                <Step1
                    miniStep={miniStep}
                    onClickNextContent={onClickNextContent}
                    ButtonWrap={ButtonWrap}
                    onClickNextMiniStep={onClickNextMiniStep}
                    scrollRef={scrollRef}
                />
            )}
            {footerStep === 1 && (
                <Step2
                    miniStep={miniStep}
                    onClickNextContent={onClickNextContent}
                    ButtonWrap={ButtonWrap}
                    onClickNextMiniStep={onClickNextMiniStep}
                    scrollRef={scrollRef}
                />
            )}
            {footerStep === 2 && (
                <Step3
                    miniStep={miniStep}
                    onClickNextContent={onClickNextContent}
                    ButtonWrap={ButtonWrap}
                    onClickNextMiniStep={onClickNextMiniStep}
                    scrollRef={scrollRef}
                />
            )}
            <div className="hugFooter">
                <Steps
                    progressDot
                    current={footerStep}
                    items={[
                        {
                            title: (<><span onClick={() => onClickNextContent(0)}>내용증명</span></>),
                            description: 'Hug 보증보험 첫단계',
                        },
                        {
                            title: (<><span onClick={() => onClickNextContent(1)}>임차권등기명령</span></>),
                            description: '임차권등기명령',
                        },
                        {
                            title: (<><span onClick={() => onClickNextContent(2)}>HUG서류준비</span></>),
                            description: (<>HUG에 제출할 <br />서류준비</>),
                        },
                        
                    ]}
                />
            </div>
        </div>
    )
}

export default Hug;
/* eslint-disable react/prop-types */
import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const AntCard = (props) => {
    // eslint-disable-next-line react/prop-types
    const { title, content, link, Icon } = props;
    console.log('Icon ::', Icon);
    return (
        <div className="antCards">
            <Link to={link}>
                <Card title={title} bordered={false}>
                    {content}
                    {/* {Icon} */}
                </Card>
            </Link>
        </div>
    )
}

AntCard.defaultProps= {
    title: "준비중입니다",
    content:'준비중입니다.'
}

AntCard.propsTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
};

export default AntCard;
import React from 'react';
import { Link } from 'react-router-dom';
import '@styles/scss/Header.scss'

const Header = () => {
    return (
        <>
            {/* Header */}
            <div id="header">
                <h1><Link to="/">안방</Link></h1>
                <ul className="util">
                    <li><Link to="/">첫화면</Link></li>
                </ul>
            </div>
            {/* //Header */}
        </>
    )
}

export default Header;